import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/layouts/Main.vue'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
        path: '*',
        name: 'NotFound',
        component: () =>
            import ( /* webpackChunkName: "errorpage" */ '@/views/full-page/Error.vue'),
        props: { isInCard: false }
    },
    {
        path: '/coming',
        component: () =>
            import ( /* webpackChunkName: "eventreglayout" */ '@/layouts/Main.vue'),
        children: [{
            meta: {
                title: 'Coming Soon'
            },
            path: 'soon',

            component: () =>
                import ( /* webpackChunkName: "comingsoon" */ '@/views/ComingSoon.vue')
        }]
    },
    // {
    //     path: '/',
    //     meta: {
    //         title: 'TorkHub'
    //     },
    //     name: 'TorkHub',
    //     redirect: '/my/profile'
    // },
    {
        path: '/auth/:mode?',
        name: 'Supa Auth',
        component: () =>
            import ( /* webpackChunkName: "auth" */ '@/views/Auth.vue')
    },
    {
        meta: {
            title: 'Welcome'
        },
        path: '/landing',
        redirect: '/signup'
    },
    {
        meta: {
            title: 'Welcome Partners'
        },
        path: '/partner/:partner_type',
        component: () =>
            import ( /* webpackChunkName: "landingPartners" */ '@/views/LandingPartners.vue')
    },
    {
        meta: {
            title: 'Welcome Media'
        },
        path: '/signup/media/outlet',
        alias: '/media/outlet',
        component: () =>
            import ( /* webpackChunkName: "landingMedia" */ '@/views/LandingMedia.vue')
    },
    {
        meta: {
            title: 'Welcome Media'
        },
        path: '/media/:sub_type',
        component: () =>
            import ( /* webpackChunkName: "landingMediaMulti" */ '@/views/LandingMediaMulti.vue')
    },
    {
        meta: {
            title: 'Welcome'
        },
        path: '/signup',
        component: () =>
            import ( /* webpackChunkName: "basiclanding" */ '@/views/LandingBasic.vue')
    },
    {
        path: '/my',
        component: MainLayout,
        redirect: 'profile',
        meta: {
            requiresAuth: true
        },
        children: [{
                meta: {
                    title: 'Profile',
                    requiresAuth: true
                },
                path: 'profile',
                name: 'profile',
                alias: '/profile',
                component: () =>
                    import ( /* webpackChunkName: "profile" */ '@/views/Profile.vue')
            },
            {
                meta: {
                    title: 'My Events',
                    requiresAuth: true
                },
                path: 'events',
                name: 'events',
                component: () =>
                    import ( /* webpackChunkName: "myevents" */ '@/views/ConsumerEvents.vue')
            },
            {
                meta: {
                    title: 'My Event Details',
                    requiresAuth: true
                },
                path: 'events/:eventID/details/:tab?',
                name: 'eventdetails',
                component: () =>
                    import ( /* webpackChunkName: "myeventDashboard" */ '@/views/ConsumerDashboard.vue')
            },
            {
                meta: {
                    title: 'My Tasks',
                    requiresAuth: true
                },
                path: 'tasks',
                name: 'tasks',
                component: () =>
                    import ( /* webpackChunkName: "mytasks" */ '@/views/Tasks.vue')
            },
            {
                meta: {
                    title: 'TelemetryViewer',
                    requiresAuth: true
                },
                path: 'telemetryviewer',
                name: 'mytelemetryviewer',
                component: () =>
                    import ( /* webpackChunkName: "telemetry" */ '@/views/TelemetryViewer.vue')
            },
            {
                meta: {
                    title: 'Home',
                    requiresAuth: true
                },
                path: 'home',
                name: 'home',
                component: () =>
                    import ( /* webpackChunkName: "telemetry" */ '@/views/Home.vue')
            },
            {
                meta: {
                    title: 'My Garage',
                    requiresAuth: true
                },
                path: 'garage',
                name: 'my garage',
                component: () =>
                    import ( /* webpackChunkName: "garage" */ '@/views/MyGarage.vue')
            },
            {
                meta: {
                    title: 'Vehicle Details',
                    requiresAuth: true
                },
                path: 'garage/vehicle/:garage_id',
                name: 'vehicleDetail',
                component: () =>
                    import ( /* webpackChunkName: "garage" */ '@/views/MyGarageVehicleDetails.vue')
            }
        ]
    },
    // End Team Pages
    // Registration Pages
    {
        path: '/2022/:eventName/tickets',
        redirect: to => {
            return { path: '/2023/:eventName/tickets' }
        }
    },
    {
        meta: {
            title: 'Tickets'
        },
        path: '/2023/:eventName/tickets',
        name: 'onlineTickets',
        component: () =>
            import ( /* webpackChunkName: "fdtickets2023" */ '@/views/FDConsumerTickets.vue')
    },
    {
        meta: {
            title: 'Gridlife Gold Pass' // TODO: Look at dynamic route names....
        },
        name: 'Season Tickets',
        path: '/season/goldpass/tickets',
        redirect: to => {
            return { path: '/season/goldpass2024/tickets' }
        },
        component: () =>
            import ( /* webpackChunkName: "seasonTickets" */ '@/views/SeasonTickets.vue')
    },
    {
        meta: {
            title: 'Gridlife Gold Pass 2024' // TODO: Look at dynamic route names....
        },
        name: 'Season Tickets',
        path: '/season/goldpass2024/tickets',

        component: () =>
            import ( /* webpackChunkName: "seasonTickets" */ '@/views/SeasonTickets2024.vue')
    },
    {
        meta: {
            title: 'Gridlife Gold Pass 2025' // TODO: Look at dynamic route names....
        },
        name: 'Season Tickets',
        path: '/season/goldpass2025/tickets',

        component: () =>
            import ( /* webpackChunkName: "seasonTickets" */ '@/views/SeasonTickets2025.vue')
    },
    {
        meta: {
            title: 'Event Tickets' // TODO: Look at dynamic route names....
        },
        name: 'tickets',
        path: '/event/:eventName/tickets/:product_type?/:user_id?',

        component: (route) =>
            import ( /* webpackChunkName: "eventTickets" */ '@/views/TicketingLoader.vue')
    },
    // Team Pages
    {
        path: '/event',
        component: () =>
            import ( /* webpackChunkName: "eventreglayout" */ '@/layouts/EventRegistration.vue'),
        children: [{
                meta: {
                    title: 'Registration'
                },
                path: 'registration/lz',

                component: () =>
                    import ( /* webpackChunkName: "lzreg" */ '@/views/LZRegistration.vue')
            },
            {
                meta: {
                    title: 'Registration'
                },
                path: 'registration/hyperfest',

                component: () =>
                    import ( /* webpackChunkName: "hyperreg" */ '@/views/HyperFestRegistration.vue')
            }
        ]
    },
    {
        path: '/media-outlet',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            notConsumer: true
        },
        children: [{
            meta: {
                section: 'Media Dashboard',
                title: 'Media Dashboard',
                requiresAdmin: false
            },
            path: 'dashboard/:team_id?',
            component: () =>
                import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
        }]
    },
    {
        path: '/media',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            notConsumer: true
        },
        children: [{
                meta: {
                    section: 'Media Dashboard',
                    title: 'Media Dashboard',
                    requiresAdmin: false
                },
                path: 'dashboard/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'Multiteam Dashboard',
                    title: 'Multiteam Dashboard',
                    requiresAdmin: false
                },
                path: ':sub_type/dashboard/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "partnerdashboard" */ '@/views/MediaMultiDashboard.vue')
            }
        ]
    },
    {
        path: '/vendor',
        component: MainLayout,
        redirect: 'vendor/dashboard',
        meta: {
            requiresAuth: true,
            notConsumer: true
        },
        children: [{
            meta: {
                section: 'Vendor Dashboard',
                title: 'Vendor Dashboard',
                requiresAdmin: false
            },
            path: 'dashboard/:team_id?',
            component: () =>
                import ( /* webpackChunkName: "vendorDashboard" */ '@/views/VendorDashboard.vue')
        }]
    },
    {
        path: '/partner',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            notConsumer: true
        },
        children: [{
                meta: {
                    section: 'Partner Dashboard',
                    title: 'Partner Dashboard',
                    requiresAdmin: false
                },
                path: ':partner_type/dashboard/:team_id?',
                name: 'Partner Dashboard',
                component: () =>
                    import ( /* webpackChunkName: "partnerdashboard" */ '@/views/PartnerDashboard.vue')
            },
            {
                meta: {
                    section: 'Partner Submission Success',
                    title: 'Partner Submission Success',
                    requiresAdmin: false
                },
                path: 'submission/:team_id',
                component: () =>
                    import ( /* webpackChunkName: "partnerdashboardreturn" */ '@/views/PartnerSubmissionReturn.vue')
            }
        ]
    },
    {
        path: '/org',
        component: MainLayout,
        redirect: 'org/dashboard',
        meta: {
            requiresAuth: true,
            requiresOrgAdmin: true,
            notConsumer: true
        },
        children: [{
                meta: {
                    section: 'Dashboard',
                    title: 'Dashboard',
                    requiresAdmin: false
                },
                path: 'dashboard',
                name: 'org-dashboard',
                component: () =>
                    import ( /* webpackChunkName: "teamdashboard" */ '@/views/OrgDashboard.vue')
            },
            {
                meta: (route) => ({
                    section: 'Dashboard',
                    title: route.params.dashboard_name + ' Dashboard',
                    requiresAdmin: false
                }),
                path: 'dashboards/:dashboard_name/:dashboardID',
                name: 'Dashboards',
                component: () =>
                    import ( /* webpackChunkName: "orgdashboards" */ '@/views/OrgDashboards.vue')
            },
            {
                meta: {
                    section: 'Approvals',
                    title: 'Approvals'
                },
                path: 'approvals',
                name: 'org-approvals',
                component: () =>
                    import ( /* webpackChunkName: "orgapprovals" */ '@/views/OrgApprovals.vue')
            },
            {
                meta: {
                    section: 'Approval',
                    title: 'Approval'
                },
                path: 'approval/:order_number?/:order_items_id?',
                name: 'org-approval',

                component: () =>
                    import ( /* webpackChunkName: "indvapproval" */ '@/views/Approval.vue')
            },
            {
                meta: {
                    section: 'Task Approval',
                    title: 'Task Approval'
                },
                path: 'task-approval/:order_number?/:order_items_id?/:team_id?',
                name: 'org-task-approval',

                component: () =>
                    import ( /* webpackChunkName: "taskapproval" */ '@/views/TaskApproval.vue')
            },
            {
                meta: {
                    section: 'Events Product View',
                    title: 'Events | All Events',
                    requiresAuth: true,
                    requiresOrgAdmin: true
                },
                path: 'events',
                name: 'org-events',
                component: () =>
                    import ( /* webpackChunkName: "events" */ '@/views/Events.vue')
            },
            {
                meta: {
                    section: 'Product View',
                    title: 'Event Products'
                },
                path: 'event-products/:event_id?',
                name: 'org-event',
                component: () =>
                    import ( /* webpackChunkName: "eventproducts" */ '@/views/EventProducts.vue')
            },
            {
                meta: {
                    section: 'Event Product Grid',
                    title: 'Event Products Grid'
                },
                path: 'event-products-all-events/:event_id?',
                name: 'org-event-all',
                component: () =>
                    import ( /* webpackChunkName: "eventproducts" */ '@/views/EventProductsAll.vue')
            },
            {
                meta: {
                    section: 'Credentials',
                    title: 'Credentials'
                },
                path: 'credentials',
                name: 'credentials',

                component: () =>
                    import ( /* webpackChunkName: "credentials" */ '@/views/OrgCredentials.vue')
            },
            {
                meta: {
                    section: 'CredentialDetails',
                    title: 'Credential Detail'
                },
                path: 'credential-details/:hard_card_id?',
                name: 'credentialDetails/:hard_card_id?',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "credentialDetails" */ '@/views/OrgCredentialDetails.vue')
            },
            {
                meta: {
                    section: 'CredentialAdd',
                    title: 'Credential Add'
                },
                path: 'credential/add',
                name: 'credential/add',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "credentialAdd" */ '@/views/OrgCredentialAdd.vue')
            },
            {
                meta: {
                    section: 'CredentialPrint',
                    title: 'Credential Print'
                },
                path: 'credentials/print',
                name: 'credentials/print',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "credentialsPrint" */ '@/views/OrgCredentialsPrint.vue')
            },
            {
                meta: {
                    section: 'PartnerApprovals',
                    title: 'Partner Approvals'
                },
                path: 'partners/approvals',
                name: 'partnerapprovals',
                component: () =>
                    import ( /* webpackChunkName: "partnerapprovals" */ '@/views/PartnerApprovals.vue')
            },
            {
                meta: {
                    section: 'HardCards',
                    title: 'Hard Cards'
                },
                path: 'hardcards',
                name: 'hardcards',
                component: () =>
                    import ( /* webpackChunkName: "hardcards" */ '@/views/HardCards.vue')
            },
            {
                meta: {
                    section: 'Orbits',
                    title: 'Orbits'
                },
                path: 'orbits',
                name: 'orbitslanding',
                component: () =>
                    import ( /* webpackChunkName: "orbits" */ '@/views/OrgOrbitsLanding.vue')
            },
            {
                meta: {
                    section: 'Orbits',
                    title: 'Orbits'
                },
                path: 'orbits/:event_name',
                name: 'orbits',
                component: () =>
                    import ( /* webpackChunkName: "orbits" */ '@/views/OrbitsView.vue')
            },
            {
                meta: {
                    title: 'HyperFest Check In Nasa',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/nasa',
                name: 'willcall-nasa',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/HFCheckInNasa.vue')
            },
            {
                meta: {
                    title: 'HyperFest Check In Powerwheels',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/powerwheels',
                name: 'willcall-powerwheels',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/HFCheckInPowerwheels.vue')
            },
            {
                meta: {
                    title: 'HyperFest Check In Media Pass',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/mediapass',
                name: 'willcall-media-pass',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/HFCheckInMediaPass.vue')
            },
            {
                meta: {
                    title: 'HyperFest Check In Rally',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/rally',
                name: 'willcall-rally',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/HFCheckInRally.vue')
            },
            {
                meta: {
                    title: 'Will Call Motorsports',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/drivers',
                name: 'willcall-drivers',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/WillCallMotorsportsGrid.vue')
            },
            {
                meta: {
                    title: 'Will Call VIP',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/midwestvip',
                name: 'willcall-midwestvip',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/GLMidwestVIPGrid.vue')
            },
            {
                meta: {
                    title: 'Will Call SIMAGIC',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/simagic',
                name: 'willcall-simagic',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/FDSIMAGICGrid.vue')
            },
            {
                meta: {
                    title: 'Will Call Ride Alongs',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'willcall/ridealong',
                name: 'willcall-ridealong',
                component: () =>
                    import ( /* webpackChunkName: "willcall" */ '@/views/FDRideAlongGrid.vue')
            },
            {
                meta: {
                    section: 'WillCall',
                    title: 'Will Call'
                },
                path: 'willcall/',
                name: 'willcalllanding',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "willCall" */ '@/views/OrgWillCallLanding.vue')
            },
            {
                meta: {
                    section: 'WillCall',
                    title: 'Will Call'
                },
                path: 'willcall/:event_name',
                name: 'willcall',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "willCall" */ '@/views/OrgWillCall.vue')
            },
            {
                meta: {
                    section: 'Media Tasks',
                    title: 'Media Tasks'
                },
                path: 'media/tasks',
                name: 'mediatasks',
                component: () =>
                    import ( /* webpackChunkName: "orgMediaTasks" */ '@/views/OrgMediaTasks.vue')
            },
            {
                meta: {
                    section: 'Media',
                    title: 'Media'
                },
                path: 'media/:event_name',
                name: 'media/:event_name',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "media" */ '@/views/OrgMedia.vue')
            },
            {
                meta: {
                    section: 'Media Allocations',
                    title: 'Media Allocations'
                },
                path: 'mediaallocations',
                name: 'mediaallocations',

                component: () =>
                    import ( /* webpackChunkName: "mediaAllocations" */ '@/views/OrgMediaAllocations.vue')
            },
            {
                meta: {
                    section: 'Parking',
                    title: 'Parking'
                },
                path: 'parking',
                name: 'parking',

                component: () =>
                    import ( /* webpackChunkName: "parking" */ '@/views/OrgParking.vue')
            },
            {
                meta: {
                    section: 'WillCallOrderDetail',
                    title: 'Will Call Order Detail'
                },
                path: 'willcallorderdetail',
                name: 'willcallorderdetail',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "willCallOrderDetail" */ '@/views/WillCallOrderDetail.vue')
            },
            {
                meta: {
                    section: 'WillCallOrderDetail',
                    title: 'Will Call Order Detail Legacy'
                },
                path: 'willcallorderdetail/legacy',
                name: 'willcallorderdetaillegacy',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "willCallOrderDetail" */ '@/views/WillCallOrderDetailOld.vue')
            },
            {
                meta: {
                    section: 'WillCallOrderDetailBkup',
                    title: 'Will Call Order Detail (old)'
                },
                path: 'willcallorderdetailbkup',
                name: 'willcallorderdetailbkup',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "willCallOrderDetailBkup" */ '@/views/OrgWillCallOrderDetail.vue')
            },
            {
                meta: {
                    section: 'Org Allocations',
                    title: 'Allocations'
                },
                path: 'partners/allocations',
                name: 'allocations',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "orgAllocations" */ '@/views/OrgAllocations.vue')
            },
            {
                meta: {
                    section: 'Allocations',
                    title: 'Allocations'
                },
                path: 'allocations',
                name: 'allocations',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "Allocations" */ '@/views/Allocations.vue')
            },
            {
                meta: {
                    section: 'Allocations',
                    title: 'Allocations'
                },
                path: 'v2/allocations',
                name: 'v2-allocations',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "Allocations" */ '@/views/OrgAllocationsV2.vue')
            },
            {
                meta: {
                    title: 'FD Driver Profiles',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'team/profiles',
                name: 'driverprofiles',
                component: () =>
                    import ( /* webpackChunkName: "reportdriverprofiles" */ '@/views/ReportDriverProfiles.vue')
            },
            {
                meta: {
                    title: 'Teams',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'teams',
                name: 'teams',
                component: () =>
                    import ( /* webpackChunkName: "teams" */ '@/views/OrgTeams.vue')
            },
            {
                meta: {
                    title: 'Team Details',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'teamdetails/:team_id/:tab?',
                name: 'teamdetails',
                component: () =>
                    import ( /* webpackChunkName: "teams" */ '@/views/OrgTeamDetail.vue')
            },
            {
                meta: {
                    title: 'Box Office',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'boxoffice/:event_name/:boxoffice_label/:reader_label/:group?',
                name: 'boxoffice/:event_name/:boxoffice_label/:reader_label',
                component: () =>
                    import ( /* webpackChunkName: "boxoffice" */ '@/views/BoxOffice.vue')
            },
            {
                meta: {
                    title: 'Box Office Scans',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'boxoffice/scans/:order_number',
                name: 'boxofficescans',
                component: () =>
                    import ( /* webpackChunkName: "boxofficescan" */ '@/views/BoxOfficeScan.vue')
            },
            {
                meta: {
                    section: 'Discount Codes',
                    title: 'Discount Codes'
                },
                path: 'discount_codes',
                name: 'discount_codes',
                props: true,

                component: () =>
                    import ( /* webpackChunkName: "orgDiscountCodes" */ '@/views/OrgDiscountCodes.vue')
            },
            {
                meta: {
                    section: 'Sponsor Activation',
                    title: 'Booth Scans'
                },
                path: 'activation',
                name: 'activation',
                component: () =>
                    import ( /* webpackChunkName: "orgDiscountCodes" */ '@/views/SponsorActivation.vue')
            },
            {
                meta: {
                    section: 'Tech Tasks',
                    title: 'Tech Tasks'
                },
                path: 'tech/tasks',
                name: 'techtasks',
                component: () =>
                    import ( /* webpackChunkName: "orgTechTasks" */ '@/views/OrgTechTasks.vue')
            },
            {
                meta: {
                    section: 'All Forms',
                    title: 'All Forms'
                },
                path: 'forms/all',
                name: 'all forms',

                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/AllFormsView.vue')
            },
            {
                meta: {
                    section: 'Form Approval',
                    title: 'Form Approval'
                },
                path: 'forms/approval-view/:form_submission_uuid',
                name: 'form approval',

                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/FormApprovalView.vue')
            },
            {
                meta: {
                    section: 'Forms',
                    title: 'Forms'
                },
                path: 'forms/:uuid/:team_id?',
                name: 'forms',
                component: () =>
                    import ( /* webpackChunkName: "orgForms" */ '@/views/OrgForms.vue')
            },
            {
                meta: {
                    title: 'FD Pit Space',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'pitspace',
                name: 'pitspace',
                component: () =>
                    import ( /* webpackChunkName: "orgForms" */ '@/views/FDPitSpace.vue')
            },
            {
                meta: {
                    title: 'Bracket',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'bracket',
                name: 'bracket',
                component: () =>
                    import ( /* webpackChunkName: "orgForms" */ '@/views/Bracket.vue')
            },
            {
                meta: {
                    section: 'HPDE',
                    title: 'HPDE'
                },
                path: 'hpde',
                name: 'hpdelanding',
                component: () =>
                    import ( /* webpackChunkName: "hpde" */ '@/views/OrgHPDELanding.vue')
            },
            {
                meta: {
                    section: 'HPDE',
                    title: 'HPDE'
                },
                path: 'hpde/:event_name',
                name: 'hpde',
                component: () =>
                    import ( /* webpackChunkName: "hpde" */ '@/views/HPDEView.vue')
            },
            {
                meta: {
                    section: 'Motorsports CheckIn',
                    title: 'Motorports CheckIn'
                },
                path: 'motorsports',
                name: 'motorsportslanding',
                component: () =>
                    import ( /* webpackChunkName: "motorsports" */ '@/views/OrgMotorsportsLanding.vue')
            },
            {
                meta: {
                    section: 'Motorsports CheckIn',
                    title: 'Motorports CheckIn'
                },
                path: 'motorsports/checkin/:record_id',
                name: 'motorsportscheckin',
                component: () =>
                    import ( /* webpackChunkName: "motorsports" */ '@/views/OrgMotorsportsCheckin.vue')
            },
            {
                meta: {
                    section: 'Motorsports CheckIn',
                    title: 'Motorports CheckIn'
                },
                path: 'motorsports/:event_name',
                name: 'motorsports',
                component: () =>
                    import ( /* webpackChunkName: "motorsports" */ '@/views/MotorsportsDataGrid.vue')
            },
            {
                meta: {
                    section: 'Broadcast',
                    title: 'Broadcast'
                },
                path: 'broadcast',
                name: 'broadcastlanding',
                component: () =>
                    import ( /* webpackChunkName: "broadcast" */ '@/views/OrgBroadcastLanding.vue')
            },
            {
                meta: {
                    section: 'Broadcast',
                    title: 'BroadCast'
                },
                path: 'broadcast/:event_name',
                name: 'broadcast',
                component: () =>
                    import ( /* webpackChunkName: "broadcast" */ '@/views/BroadcastView.vue')
            },
            {
                meta: {
                    section: 'Transponders',
                    title: 'Transponders'
                },
                path: 'transponders',
                name: 'transponders',
                component: () =>
                    import ( /* webpackChunkName: "transponders" */ '@/views/TransponderView.vue')
            },
            {
                meta: {
                    section: 'Vehicle Numbers',
                    title: 'Vehicle Numbers'
                },
                path: 'vehiclenumbers',
                name: 'vehicle numbers',
                component: () =>
                    import ( /* webpackChunkName: "vehiclenumbers" */ '@/views/VehicleNumbersView.vue')
            },
            {
                meta: {
                    title: 'TelemetryViewer',
                    requiresAuth: true,
                    orgAccess: [1],
                    roleAccess: [366]
                },
                path: 'telemetryviewer',
                name: 'orgtelemetryviewer',
                component: () =>
                    import ( /* webpackChunkName: "telemetry" */ '@/views/TelemetryViewer.vue')
            },
            {
                meta: {
                    title: 'Products',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'products',
                name: 'products',
                component: () =>
                    import ( /* webpackChunkName: "products" */ '@/views/OrgProducts.vue')
            },
            {
                meta: {
                    title: 'Products',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'productDetails/:product_id?/:page?/:event_product_id?/:step?',
                name: 'product details',
                component: () =>
                    import ( /* webpackChunkName: "products" */ '@/views/OrgProductDetails.vue')
            },
            {
                meta: {
                    section: 'Comp Forms',
                    title: 'Comp Forms'
                },
                path: 'compforms',
                name: 'compforms',
                component: () =>
                    import ( /* webpackChunkName: "orgForms" */ '@/views/OrgCompForms.vue')
            },
            {
                meta: {
                    title: 'Events',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'eventsbuilder',
                name: 'events builder',
                component: () =>
                    import ( /* webpackChunkName: "products" */ '@/views/OrgEvents.vue')
            },
            {
                meta: {
                    title: 'Events',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'eventDetails/:event_id?/:page?/:step?',
                name: 'event details',
                component: () =>
                    import ( /* webpackChunkName: "products" */ '@/views/OrgEventDetails.vue')
            },
            {
                meta: {
                    title: 'Orders',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true,
                    roleAccess: [42]
                },
                path: 'orders',
                name: 'orders',
                component: () =>
                    import ( /* webpackChunkName: "ord" */ '@/views/org/OrdersList.vue')
            },
            {
                meta: {
                    title: 'Order Details',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true,
                    roleAccess: [42]
                },
                path: 'orders/:order_number',
                name: 'order details',
                component: () =>
                    import ( /* webpackChunkName: "ord" */ '@/views/OrderDetails.vue')
            },
            {
                meta: {
                    title: 'Crew List',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'crewlist',
                name: 'crew list',
                component: () =>
                    import ( /* webpackChunkName: "crew" */ '@/views/OrgCrewList.vue')
            },
            {
                meta: {
                    title: 'Emergency Contacts',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'emergencycontacts',
                name: 'emergency contacts',
                component: () =>
                    import ( /* webpackChunkName: "contacts" */ '@/views/OrgEmergencyContacts.vue')
            },
            {
                meta: {
                    title: 'Motorsports Approvals',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'motorsportsapprovals',
                name: 'motorsports approvals',
                component: () =>
                    import ( /* webpackChunkName: "contacts" */ '@/views/OrgMotorsportsApprovals.vue')
            },
            {
                meta: {
                    title: 'Driver Products',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true,
                    roleAccess: [42]
                },
                path: 'driverproducts',
                name: 'driver products',
                component: () =>
                    import ( /* webpackChunkName: "org" */ '@/views/OrgDriverProducts.vue')
            },
            {
                meta: {
                    title: 'Groups',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'groups',
                name: 'groups',
                component: () =>
                    import ( /* webpackChunkName: "org" */ '@/views/OrgGroups.vue')
            },
            {
                meta: {
                    title: 'Group Users',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'groupusers/:group_id',
                name: 'groupusers',
                component: () =>
                    import ( /* webpackChunkName: "org" */ '@/views/OrgGroupUsers.vue')
            },
            {
                meta: {
                    section: 'GLTC',
                    title: 'GLTC'
                },
                path: 'gltc',
                name: 'gltclanding',
                component: () =>
                    import ( /* webpackChunkName: "gltc" */ '@/views/OrgGLTCLanding.vue')
            },
            {
                meta: {
                    section: 'GLTC',
                    title: 'GLTC'
                },
                path: 'gltc/:event_name',
                name: 'gltc',
                component: () =>
                    import ( /* webpackChunkName: "gltc" */ '@/views/GLTCView.vue')
            },
            {
                meta: {
                    section: 'Time Attack',
                    title: 'Time Attack'
                },
                path: 'ta',
                name: 'talanding',
                component: () =>
                    import ( /* webpackChunkName: "ta" */ '@/views/OrgTALanding.vue')
            },
            {
                meta: {
                    section: 'Time Attack',
                    title: 'Time Attack'
                },
                path: 'ta/:event_name',
                name: 'ta',
                component: () =>
                    import ( /* webpackChunkName: "ta" */ '@/views/TAView.vue')
            },
            {
                meta: {
                    section: 'Rush',
                    title: 'Rush'
                },
                path: 'rush',
                name: 'rushlanding',
                component: () =>
                    import ( /* webpackChunkName: "rush" */ '@/views/OrgRushLanding.vue')
            },
            {
                meta: {
                    section: 'Rush',
                    title: 'Rush'
                },
                path: 'rush/:event_name',
                name: 'rush',
                component: () =>
                    import ( /* webpackChunkName: "rush" */ '@/views/RushView.vue')
            }
        ]
    },
    // End Team Pages
    // Registration Pages
    {
        path: '/team',
        component: MainLayout,
        meta: {
            requiresAuth: true,
            notConsumer: true
        },
        children: [{
                meta: {
                    section: 'Team Dashboard 2023',
                    title: 'Team Dashboard 2023',
                    requiresTeamAdmin: true
                },
                path: 'dashboard/:team_id/:tab?',
                name: 'teamdashboard',

                component: () =>
                    import ( /* webpackChunkName: "teamdashboard_2023" */ '@/views/TeamDashboard_2023.vue')
            },
            {
                meta: {
                    section: 'PRO Team Dashboard',
                    title: 'PRO Team Dashboard',
                    requiresTeamPro: true
                },
                path: 'dashboard/team/pro/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'PROSPEC Team Dashboard',
                    title: 'PROSPEC Team Dashboard',
                    requiresTeamProSpec: true
                },
                path: 'dashboard/team/prospec/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'Partner Staff Team Dashboard',
                    title: 'Partner Staff Team Dashboard',
                    requiresPartnerStaff: true
                },
                path: 'dashboard/partner/staff/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'Media Outlet Team Dashboard',
                    title: 'Media Outlet Team Dashboard',
                    requiresMediaOutlet: true
                },
                path: 'dashboard/media/outlet/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'Media Multi Team Dashboard',
                    title: 'Media Multi Team Dashboard',
                    requiresMediaMulti: true
                },
                path: 'dashboard/media/multi/:team_id?',
                component: () =>
                    import ( /* webpackChunkName: "mediaDashboard" */ '@/views/MediaDashboard.vue')
            },
            {
                meta: {
                    section: 'Team Dashboard',
                    title: 'Team Dashboard'
                },
                path: 'dashboard/:ps/:status?',

                component: () =>
                    import ( /* webpackChunkName: "teamdashboard" */ '@/views/TeamDashboard.vue')
            },

            {
                meta: {
                    section: 'Crew',
                    title: 'Team | Crew',
                    requiresTeamCrew: true
                },
                path: 'crew/:team_id',
                name: 'Team Crew',
                component: () =>
                    import ( /* webpackChunkName: "teamcrew" */ '@/views/TeamCrew.vue')
            },
            {
                meta: {
                    section: 'CrewMember',
                    title: 'Team | Crew Member'
                        // requiresTeamCrew: true
                },
                path: 'crew/:team_id/edit-crew-member/:user_id',
                name: 'Team Crew Member',
                component: () =>
                    import ( /* webpackChunkName: "teamcrew" */ '@/views/TeamCrewEditMember.vue')
            },
            {
                meta: {
                    section: 'CrewMember',
                    title: 'Team | Crew Member'
                        // requiresTeamCrew: true
                },
                path: 'crew/:team_id/member/:team_user_id/:tab?',
                name: 'Team Crew Member Details',
                component: () =>
                    import ( /* webpackChunkName: "teamcrew" */ '@/views/TeamCrewMemberDetailPage.vue')
            },
            {
                meta: {
                    section: 'Registration',
                    title: 'TH | Table'
                },
                path: 'table-sample',

                component: () =>
                    import ( /* webpackChunkName: "table-testing" */ '@/views/TH-Table-Component.vue')
            },
            {
                meta: {
                    section: 'Approval',
                    title: 'Approval'
                },
                path: 'approval/:order_number?/:order_items_id?',
                name: 'approval',

                component: () =>
                    import ( /* webpackChunkName: "approval" */ '@/views/Approval.vue')
            },
            {
                meta: {
                    section: 'To-Do Items',
                    title: 'Team | To-Do Items'
                },
                path: 'todo',

                component: () =>
                    import ( /* webpackChunkName: "teamtodo" */ '@/views/TeamToDo.vue')
            },
            {
                meta: {
                    section: 'Profiles',
                    title: 'Team | Profiles'
                },
                path: 'profiles/:team_id?',

                component: () =>
                    import ( /* webpackChunkName: "teamtodo" */ '@/views/TeamProfiles.vue')
            },
            {
                meta: {
                    section: 'CrewMember',
                    title: 'Team | Crew Member'
                        // requiresTeamCrew: true
                },
                path: 'crew/:team_id?/edit-crew-member/:user_id?',
                name: 'Team Crew Member',
                component: () =>
                    import ( /* webpackChunkName: "teamcrew" */ '@/views/TeamCrewEditMember.vue')
            },
            {
                meta: {
                    section: 'Table Component',
                    title: 'Team | Table Component'
                },
                path: 'table-component',

                component: () =>
                    import ( /* webpackChunkName: "teamtodo" */ '@/views/TableComponent.vue')
            },
            {
                meta: {
                    section: 'Team Application',
                    title: 'Team | Application'
                },
                path: 'application',
                name: 'application',

                component: () =>
                    import ( /* webpackChunkName: "teamapplication" */ '@/views/TeamApplication.vue')
            },
            {
                meta: {
                    section: 'Guest Tickets',
                    title: 'Team | Guest Tickets Landing',
                    requiresGuestTickets: true
                },
                path: ':team_id/guesttickets',
                name: 'guest tickets landing',
                props: true,
                component: () =>
                    import ( /* webpackChunkName: "guesttickets" */ '@/views/OrgGuestTicketsLanding.vue')
            },
            {
                meta: {
                    section: 'Guest Tickets',
                    title: 'Team | Guest Tickets',
                    requiresGuestTickets: true
                },
                path: ':team_id/guesttickets/:event_name',
                name: 'guest tickets',
                props: true,
                component: () =>
                    import ( /* webpackChunkName: "guesttickets" */ '@/views/GuestTickets.vue')
            },
            {
                meta: {
                    section: 'Guest Tickets',
                    title: 'Team | Guest Tickets | Purchase Tickets'
                },
                path: 'purchasetickets/:event_name/:team_id',
                name: 'purchase guest tickets',

                component: () =>
                    import ( /* webpackChunkName: "purchaseguesttickets" */ '@/views/PurchaseGuestTickets.vue')
            },
            {
                meta: {
                    section: 'Guest Tickets',
                    title: 'Team | Guest Tickets | Allocate Tickets'
                },
                path: 'allocate-tickets/:team_id/:team_id/:event_id',
                name: 'allocate-tickets',

                component: () =>
                    import ( /* webpackChunkName: "allocateguesttickets" */ '@/views/AllocateGuestTickets.vue')
            },
            {
                meta: {
                    title: 'TelemetryViewer',
                    requiresAuth: true,
                    orgAccess: [1],
                    roleAccess: [366]
                },
                path: 'telemetryviewer/:team_id',
                name: 'teamtelemetryviewer',
                component: () =>
                    import ( /* webpackChunkName: "telemetry" */ '@/views/TelemetryViewer.vue')
            }

        ]
    },

    {
        path: '/torkhub',
        component: MainLayout,
        redirect: 'features',
        meta: {
            requiresAuth: true
        },
        children: [{
                meta: {
                    title: 'Features',
                    requiresAuth: true,
                    requiresSpecialSauce: true
                },
                path: 'features',
                name: 'features',
                component: () =>
                    import ( /* webpackChunkName: "thfeature" */ '@/views/TorkHub.vue')
            },
            {
                meta: {
                    title: 'TelemetryViewer',
                    requiresAuth: true,
                    orgAccess: [1],
                    roleAccess: [366]
                },
                path: 'telemetryviewer',
                name: 'thtelemetryviewer',
                component: () =>
                    import ( /* webpackChunkName: "telemetry" */ '@/views/TelemetryViewer.vue')
            },
            {
                meta: {
                    title: 'ws',
                    requiresAuth: true
                },
                path: 'ws',
                name: 'web socket test',
                component: () =>
                    import ( /* webpackChunkName: "websocket" */ '@/views/WebSocketViewer.vue')
            }
        ]
    },

    {
        path: '/report',
        component: MainLayout,
        redirect: 'report/deposits',
        meta: {
            requiresAuth: true,
            requiresOrgAdmin: true,
            notConsumer: true
        },
        children: [{
            meta: {
                title: 'Orders Detailed',
                requiresAuth: true,
                requiresOrgAdmin: true,
                notConsumer: true
            },
            path: 'deposits',
            name: 'deposits',
            component: () =>
                import ( /* webpackChunkName: "reportorderdetails" */ '@/views/ReportOrderDetails.vue')
        }]
    },
    {
        meta: {
            title: 'GRIDLIFE Gold Pass 2023'
        },
        path: '/signup/:partner/:partner_type',
        component: () =>
            import ( /* webpackChunkName: "landingPartnersAAA" */ '@/views/LandingPartners.vue')
    },
    {
        meta: {
            title: 'Order Confirmation'
        },
        path: '/order/confirmation/:event_url_name',
        component: () =>
            import ( /* webpackChunkName: "orderConfirmation" */ '@/views/OrderConfirmation.vue')
    },
    {
        path: '/form',
        component: MainLayout,
        meta: {
            requiresAuth: true
        },
        children: [{
                meta: {
                    title: 'Form Submitted'
                },
                path: 'submitted',
                name: 'form submitted',
                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/FormSubmitted.vue')
            },
            {
                meta: {
                    section: 'Form Builder',
                    title: 'Form Builder',
                    requiresAuth: true,
                    requiresOrgAdmin: true,
                    notConsumer: true
                },
                path: 'builder',
                name: 'form builder',

                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/FormBuilderView.vue')
            },
            {
                meta: {
                    section: 'Your Form',
                    title: 'Your Form'
                },
                path: 'your-form/:team_id?/:uuid?/:task_id?',
                name: 'your form',

                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/YourFormView.vue')
            },
            {
                meta: {
                    section: 'Form',
                    title: 'Form'
                },
                path: ':uuid?/:team_id?',
                name: 'form',

                component: () =>
                    import ( /* webpackChunkName: "forms" */ '@/views/FormView.vue')
            }
        ]
    }
    // END Reg Pages
    // sample component routes for reference only
    // {
    //     path: '/samples',
    //     component: MainLayout,
    //     redirect: 'samples/tables',
    //     meta: {
    //         requiresAuth: true,
    //         notConsumer: true
    //     },
    //     children: [{
    //             meta: {
    //                 title: 'Tables'
    //             },
    //             path: 'tables',
    //             name: 'tables',
    //             component: () =>
    //                 import ( /* webpackChunkName: "tables" */ '@/views/Tables.vue')
    //         },
    //         {
    //             meta: {
    //                 title: 'Forms'
    //             },
    //             path: 'forms',
    //             name: 'forms',
    //             component: () =>
    //                 import ( /* webpackChunkName: "forms" */ '@/views/Forms.vue')
    //         },
    //         {
    //             meta: {
    //                 title: 'New Client'
    //             },
    //             path: 'client/new',
    //             name: 'client.new',
    //             component: () =>
    //                 import ( /* webpackChunkName: "client-form" */ '@/views/ClientForm.vue')
    //         },
    //         {
    //             meta: {
    //                 title: 'Edit Client'
    //             },
    //             path: 'client/:id',
    //             name: 'client.edit',
    //             component: () =>
    //                 import ( /* webpackChunkName: "client-form" */ '@/views/ClientForm.vue'),
    //             props: true
    //         },
    //         {
    //             path: 'full-page',
    //             component: () =>
    //                 import ( /* webpackChunkName: "full-page" */ '@/views/FullPage.vue'),
    //             children: [{
    //                     meta: {
    //                         title: 'Login'
    //                     },
    //                     path: '/login',
    //                     name: 'login',
    //                     component: () =>
    //                         import ( /* webpackChunkName: "full-page" */ '@/views/full-page/Login.vue')
    //                 },
    //                 {
    //                     meta: {
    //                         title: 'Password Recovery'
    //                     },
    //                     path: '/password-recovery',
    //                     name: 'password-recovery',
    //                     component: () =>
    //                         import ( /* webpackChunkName: "full-page" */ '@/views/full-page/PasswordRecovery.vue')
    //                 },
    //                 {
    //                     meta: {
    //                         title: 'Error v.1'
    //                     },
    //                     path: '/error-in-card',
    //                     name: 'error-in-card',
    //                     component: () =>
    //                         import ( /* webpackChunkName: "full-page" */ '@/views/full-page/Error.vue')
    //                 },
    //                 {
    //                     meta: {
    //                         title: 'Error v.2'
    //                     },
    //                     path: '/error-simple',
    //                     name: 'error-simple',
    //                     component: () =>
    //                         import ( /* webpackChunkName: "full-page" */ '@/views/full-page/Error.vue'),
    //                     props: { isInCard: false }
    //                 },
    //                 {
    //                     meta: {
    //                         title: 'Lock screen'
    //                     },
    //                     path: '/lock-screen',
    //                     name: 'lock-screen',
    //                     component: () =>
    //                         import ( /* webpackChunkName: "full-page" */ '@/views/full-page/LockScreen.vue')
    //                 }
    //             ]
    //         },
    //         {
    //             meta: {
    //                 section: 'Will Call',
    //                 title: 'NewOrder',
    //                 meta: {
    //                     requiresAuth: true
    //                 },
    //                 subs: [
    //                     { title: 'Tickets', link: '/willcall/tickets' },
    //                     { title: 'Create New', link: '/orders/new' }
    //                 ]
    //             },
    //             path: '/orders/new',
    //             name: 'neworder',
    //             component: () =>
    //                 import ( /* webpackChunkName: "neworder" */ '../views/TH-New-Order.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Seat Charts',
    //                 title: 'Seating Selector',
    //                 meta: {
    //                     requiresAuth: true
    //                 },
    //                 subs: [
    //                     { title: 'Seating Selector', link: '/manager/seatselector' },
    //                     { title: 'Chart Manager', link: '/manager/chartmanager' },
    //                     { title: 'Chart Designer', link: '/manager/chartdesigner' },
    //                     { title: 'Event Manager', link: '/manager/eventmanager' }
    //                 ]
    //             },
    //             path: '/manager/seatselector',
    //             name: 'seats',

    //             component: () =>
    //                 import ( /* webpackChunkName: "seats" */ '../views/SeatSelector.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Seat Charts',
    //                 title: 'Chart Manager',
    //                 meta: {
    //                     requiresAuth: true
    //                 },
    //                 subs: [
    //                     { title: 'Seating Selector', link: '/manager/seatselector' },
    //                     { title: 'Chart Manager', link: '/manager/chartmanager' },
    //                     { title: 'Chart Designer', link: '/manager/chartdesigner' },
    //                     { title: 'Event Manager', link: '/manager/eventmanager' }
    //                 ]
    //             },
    //             path: '/manager/chartmanager',
    //             name: 'chartmanager',

    //             component: () =>
    //                 import ( /* webpackChunkName: "seats" */ '../views/ChartManager.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Seat Charts',
    //                 title: 'Event Manager',
    //                 meta: {
    //                     requiresAuth: true
    //                 },
    //                 subs: [
    //                     { title: 'Seating Selector', link: '/manager/seatselector' },
    //                     { title: 'Chart Manager', link: '/manager/chartmanager' },
    //                     { title: 'Chart Designer', link: '/manager/chartdesigner' },
    //                     { title: 'Event Manager', link: '/manager/eventmanager' }
    //                 ]
    //             },
    //             path: '/manager/eventmanager',
    //             name: 'eventmanager',

    //             component: () =>
    //                 import ( /* webpackChunkName: "seats" */ '../views/EventManager.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Seat Charts',
    //                 title: 'Chart Designer',
    //                 meta: {
    //                     requiresAuth: true
    //                 },
    //                 subs: [
    //                     { title: 'Seating Selector', link: '/manager/seatselector' },
    //                     { title: 'Chart Manager', link: '/manager/chartmanager' },
    //                     { title: 'Chart Designer', link: '/manager/chartdesigner' },
    //                     { title: 'Event Manager', link: '/manager/eventmanager' }
    //                 ]
    //             },
    //             path: '/manager/chartdesigner',
    //             name: 'chartdesigner',

    //             component: () =>
    //                 import ( /* webpackChunkName: "seats" */ '../views/ChartDesigner.vue')
    //         },
    //         {
    //             meta: {
    //                 title: 'Team Dashboard - Crew',
    //                 requiresAuth: true
    //             },
    //             path: '/td-crew',
    //             name: 'td-crew',

    //             component: () =>
    //                 import ( /* webpackChunkName: "crew" */ '../views/TH-TD-Crew.vue')
    //         },
    //         // Team Pages
    //         // End Team Pages
    //         {
    //             meta: {
    //                 section: 'Team Dashboard',
    //                 requiresAuth: true,
    //                 title: 'Hard Cards',
    //                 subs: [
    //                     { title: 'Guests', link: '/dashboards/teams/guests' }
    //                 ]
    //             },
    //             path: '/dashboards/teams/hardcards',

    //             component: () =>
    //                 import ( /* webpackChunkName: "hardcards" */ '../views/TH-TD-HardCards.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Dashboard',
    //                 title: 'Guests',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'Guests', link: '/dashboards/teams/guests' }
    //                 ]
    //             },
    //             path: '/dashboards/teams/guests',
    //             name: 'td-guests',

    //             component: () =>
    //                 import ( /* webpackChunkName: "guests" */ '../views/TH-TD-Guests.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Will Call',
    //                 title: 'Orders',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'Tickets', link: '/willcall/tickets' }
    //                     // { title: 'Orders', link: '/willcall/orders' }
    //                 ]
    //             },
    //             path: '/willcall/orders',
    //             name: 'wc-orders',

    //             component: () =>
    //                 import ( /* webpackChunkName: "ordersall" */ '../views/TH-WC-Orders.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Will Call',
    //                 title: 'Tickets',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'Tickets', link: '/willcall/tickets' },
    //                     { title: 'Create New', link: '/orders/new' }
    //                 ]
    //             },
    //             path: '/willcall/tickets',
    //             name: 'wc-tickets',

    //             component: () =>
    //                 import ( /* webpackChunkName: "ticketsall" */ '../views/TH-WC-Tickets.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Will Call',
    //                 title: 'Ticket Details',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'Tickets', link: '/willcall/tickets' }
    //                     // { title: 'Orders', link: '/willcall/orders' }
    //                 ]
    //             },
    //             path: '/willcall/tickets/:ticket_number',
    //             name: 'wc-ticket-details',

    //             component: () =>
    //                 import ( /* webpackChunkName: "ticketdetails" */ '../views/TH-WC-TicketDetails.vue'),
    //             props: true
    //         },
    //         {
    //             meta: {
    //                 section: 'Will Call',
    //                 title: 'Order Details',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'Tickets', link: '/willcall/tickets' },
    //                     { title: 'Orders', link: '/willcall/orders' }
    //                 ]
    //             },
    //             path: '/willcall/orders/:order_number',
    //             name: 'wc-order-details',
    //             component: () =>
    //                 import ( /* webpackChunkName: "orderdetails" */ '../views/TH-WC-OrderDetails.vue'),
    //             props: true
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Registration',
    //                 title: 'Documents',
    //                 requiresAuth: true
    //             },
    //             path: '/teams/registration/documents',
    //             name: 'tr-documents',

    //             component: () =>
    //                 import ( /* webpackChunkName: "teamdocs" */ '../views/TH-TR-Documents.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Registration',
    //                 title: 'Pit Space',
    //                 requiresAuth: true
    //             },
    //             path: '/teams/registration/pitspace',
    //             name: 'tr-pitspace',

    //             component: () =>
    //                 import ( /* webpackChunkName: "pitspace" */ '../views/TH-TR-PitSpace.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Crew',
    //                 title: 'Team Crew',
    //                 requiresAuth: true
    //             },
    //             path: '/teams/registration/contacts',
    //             name: 'tr-maincontacts',

    //             component: () =>
    //                 import ( /* webpackChunkName: "contacts" */ '../views/TH-TR-MainContacts.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Registration',
    //                 title: 'Prize Money',
    //                 requiresAuth: true
    //             },
    //             path: '/teams/registration/prizemoney',
    //             name: 'tr-prizemoney',

    //             component: () =>
    //                 import ( /* webpackChunkName: "pmoney" */ '../views/TH-TR-PrizeMoney.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Team Registration',
    //                 title: 'Marketing',
    //                 requiresAuth: true
    //             },
    //             path: '/teams/registration/marketing',
    //             name: 'tr-marketing',

    //             component: () =>
    //                 import ( /* webpackChunkName: "marketing" */ '../views/TH-TR-Marketing.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Hard Cards',
    //                 title: 'Hard Cards',
    //                 requiresAuth: true,
    //                 subs: [
    //                     { title: 'All Cards', link: '/hardcards/all' },
    //                     { title: 'Templates', link: '/hardcards/templates' },
    //                     { title: 'Requests', link: '/hardcards/requests' },
    //                     { title: 'Registrations', link: '/hardcards/registrations' }
    //                 ]
    //             },
    //             path: '/hardcards/requests',
    //             name: 'hardCardRequests',

    //             component: () =>
    //                 import ( /* webpackChunkName: "hc-requests" */ '../views/TH-HC-Requests.vue')
    //         },
    //         {
    //             meta: {
    //                 section: 'Hard Cards',
    //                 title: 'Hard Cards',
    //                 requiresAuth: true
    //             },
    //             path: '/hardcards/all',
    //             name: 'hardcards',

    //             component: () =>
    //                 import ( /* webpackChunkName: "hardcards" */ '../views/TH-HC-Issued.vue')
    //         }
    //     ]
    // }
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router